const letters = /^[A-Za-z]+$/;

export const WORDS = shuffle([
  'aaahh',
  'aaalt',
  'aalen',
  'aasen',
  'abort',
  'about',
  'above',
  'adall',
  'added',
  'adder',
  'addet',
  'adell',
  'adept',
  'adina',
  'admin',
  'admit',
  'adobe',
  'adolf',
  'adopt',
  'adore',
  'afaik',
  'affen',
  'agent',
  'aggro',
  'agile',
  'agree',
  'agurk',
  'ahaha',
  'ahead',
  'ahmed',
  'aiaia',
  'aight',
  'aires',
  'ajour',
  'akira',
  'akita',
  'akkur',
  'aksel',
  'aksen',
  'aksje',
  'akter',
  'aktig',
  'aktiv',
  'akutt',
  'alarm',
  'albue',
  'album',
  'alder',
  'aldet',
  'aldre',
  'aldri',
  'alene',
  'alert',
  'alexa',
  'alias',
  'alice',
  'alien',
  'alike',
  'alita',
  'alive',
  'allah',
  'allan',
  'aller',
  'alles',
  'allie',
  'allow',
  'alone',
  'along',
  'alpha',
  'alrdi',
  'alter',
  'alvik',
  'alvin',
  'alvor',
  'amber',
  'amigo',
  'among',
  'anale',
  'anbud',
  'andel',
  'andra',
  'andre',
  'angel',
  'anger',
  'angle',
  'angre',
  'angry',
  'angst',
  'angus',
  'aning',
  'anita',
  'ankel',
  'anker',
  'anket',
  'annen',
  'annet',
  'anrop',
  'anser',
  'anses',
  'anslo',
  'antar',
  'antok',
  'anton',
  'apati',
  'aphso',
  'apier',
  'apiet',
  'appen',
  'apper',
  'apple',
  'apply',
  'april',
  'arcus',
  'ardoq',
  'areal',
  'areas',
  'arena',
  'argon',
  'argue',
  'ariel',
  'arild',
  'aring',
  'arion',
  'arise',
  'arisk',
  'arjan',
  'arket',
  'armas',
  'armen',
  'armer',
  'armor',
  'aroma',
  'array',
  'arrow',
  'arter',
  'artig',
  'arvet',
  'arvid',
  'asana',
  'asked',
  'askim',
  'aslak',
  'aslan',
  'aslet',
  'asoka',
  'assam',
  'async',
  'atmed',
  'atort',
  'aucky',
  'audio',
  'audun',
  'avail',
  'avisa',
  'avkom',
  'avlet',
  'avoid',
  'avtar',
  'avvei',
  'avvik',
  'avvis',
  'await',
  'award',
  'azure',
  'baard',
  'babel',
  'babes',
  'babla',
  'backe',
  'bacon',
  'bader',
  'badet',
  'bagen',
  'baila',
  'baile',
  'baily',
  'bajas',
  'baken',
  'baker',
  'bakst',
  'balle',
  'balls',
  'baloo',
  'balto',
  'bambi',
  'bamse',
  'banal',
  'banan',
  'banen',
  'baner',
  'banga',
  'banks',
  'banna',
  'banne',
  'barca',
  'baren',
  'barer',
  'barna',
  'barne',
  'baron',
  'based',
  'basic',
  'basis',
  'basse',
  'baste',
  'batch',
  'bayer',
  'bbday',
  'beach',
  'beans',
  'bears',
  'beast',
  'beats',
  'bebbe',
  'bebbi',
  'bebby',
  'becks',
  'bedre',
  'beefy',
  'beers',
  'begge',
  'behag',
  'behov',
  'beige',
  'beina',
  'being',
  'beist',
  'bella',
  'belle',
  'bello',
  'below',
  'bends',
  'benet',
  'benja',
  'benny',
  'bente',
  'berit',
  'bernt',
  'berre',
  'berry',
  'berta',
  'besok',
  'bessy',
  'beste',
  'besto',
  'bests',
  'betal',
  'betas',
  'betet',
  'betre',
  'betta',
  'bette',
  'betty',
  'betyr',
  'bevis',
  'bibbi',
  'bicon',
  'biden',
  'bidet',
  'bidne',
  'bidra',
  'bidro',
  'biggi',
  'biker',
  'bikes',
  'bilag',
  'bilbo',
  'bilde',
  'bilen',
  'biler',
  'billy',
  'bilyd',
  'binde',
  'bingo',
  'bioen',
  'birds',
  'birra',
  'biten',
  'biter',
  'bitte',
  'bizzi',
  'bizzy',
  'bjorn',
  'black',
  'blade',
  'blake',
  'blakk',
  'blanc',
  'bland',
  'blank',
  'blant',
  'blast',
  'blaze',
  'bleie',
  'blekk',
  'blend',
  'blest',
  'blide',
  'blikk',
  'blind',
  'blink',
  'blire',
  'blitt',
  'blobs',
  'blogg',
  'blogs',
  'blokk',
  'blood',
  'bloom',
  'blown',
  'blund',
  'blunk',
  'blush',
  'board',
  'bobby',
  'boble',
  'bodde',
  'boden',
  'bodil',
  'boers',
  'bogen',
  'bogey',
  'bogus',
  'bohus',
  'boien',
  'boiii',
  'boiis',
  'boken',
  'bolia',
  'bolig',
  'bolla',
  'bolle',
  'bomba',
  'bombe',
  'bombs',
  'bomma',
  'boner',
  'bones',
  'bonjo',
  'bonni',
  'bonon',
  'bonus',
  'bonzo',
  'booka',
  'booke',
  'books',
  'boome',
  'boost',
  'booty',
  'booze',
  'borch',
  'borde',
  'boris',
  'borte',
  'borti',
  'borud',
  'bosse',
  'boten',
  'boter',
  'botez',
  'botti',
  'boxen',
  'boxer',
  'boxes',
  'boyen',
  'boysa',
  'boyza',
  'brace',
  'brage',
  'brain',
  'brakk',
  'brakt',
  'brand',
  'brann',
  'brant',
  'bratt',
  'bravo',
  'brayn',
  'bread',
  'break',
  'brede',
  'bredt',
  'brent',
  'brews',
  'brian',
  'brief',
  'brife',
  'bring',
  'brisj',
  'broby',
  'broer',
  'broke',
  'broom',
  'brooo',
  'brreg',
  'brudd',
  'bruka',
  'bruke',
  'bruks',
  'brukt',
  'brune',
  'bruno',
  'brunt',
  'brush',
  'brusj',
  'brute',
  'brutt',
  'brydd',
  'bryet',
  'brygg',
  'bryne',
  'bryst',
  'bryte',
  'bsuen',
  'btspt',
  'bubba',
  'bucks',
  'bucky',
  'buddy',
  'budet',
  'budge',
  'buffy',
  'bugen',
  'bugga',
  'bugge',
  'buggy',
  'bugsa',
  'build',
  'built',
  'bukse',
  'bulle',
  'bumpa',
  'bumpe',
  'bumpy',
  'bunke',
  'bunns',
  'bunny',
  'burde',
  'buret',
  'burgs',
  'bursj',
  'bussi',
  'busta',
  'busty',
  'butan',
  'butta',
  'buyin',
  'buzzz',
  'bybil',
  'bydel',
  'byene',
  'byens',
  'byenv',
  'bygde',
  'bygge',
  'byner',
  'bytes',
  'bytta',
  'bytte',
  'bytur',
  'cabin',
  'cache',
  'caddy',
  'cairn',
  'caisa',
  'caked',
  'calle',
  'calls',
  'calon',
  'campo',
  'candy',
  'canoe',
  'canon',
  'capra',
  'cards',
  'cares',
  'cargo',
  'carma',
  'carry',
  'carte',
  'casen',
  'cases',
  'caset',
  'cashe',
  'catch',
  'cause',
  'cease',
  'celle',
  'centa',
  'ceoen',
  'cerry',
  'certs',
  'cesar',
  'cfurl',
  'chain',
  'champ',
  'chaos',
  'charm',
  'chars',
  'chart',
  'cheap',
  'cheat',
  'check',
  'cheek',
  'chess',
  'chevy',
  'chick',
  'chico',
  'chics',
  'chief',
  'chika',
  'child',
  'chili',
  'chill',
  'china',
  'chine',
  'chips',
  'choka',
  'chown',
  'chris',
  'chunk',
  'churn',
  'ciara',
  'cider',
  'cigar',
  'cinco',
  'cindy',
  'cinta',
  'cipio',
  'circa',
  'cirka',
  'claim',
  'clang',
  'clara',
  'clash',
  'class',
  'clean',
  'clear',
  'click',
  'climb',
  'climo',
  'clock',
  'clone',
  'closa',
  'close',
  'cloth',
  'cloud',
  'clubh',
  'clubs',
  'clues',
  'cluet',
  'cname',
  'coach',
  'cocoa',
  'coded',
  'coden',
  'codes',
  'codex',
  'cofee',
  'cohen',
  'coins',
  'coker',
  'colie',
  'colin',
  'colli',
  'color',
  'combo',
  'comes',
  'comfy',
  'comic',
  'comma',
  'compo',
  'comte',
  'const',
  'conti',
  'cores',
  'corgi',
  'corny',
  'corso',
  'costa',
  'costs',
  'cotet',
  'couch',
  'cough',
  'could',
  'count',
  'court',
  'cover',
  'covid',
  'cpuen',
  'craft',
  'craig',
  'crate',
  'crazy',
  'creds',
  'creme',
  'cross',
  'crowd',
  'crown',
  'crude',
  'cscoe',
  'cssen',
  'csven',
  'ctags',
  'curly',
  'curve',
  'cvene',
  'cyber',
  'daamn',
  'dachs',
  'dacks',
  'dadda',
  'daddy',
  'dafuq',
  'dagan',
  'dagen',
  'dager',
  'dages',
  'daily',
  'daisy',
  'dakar',
  'dalen',
  'dalle',
  'damen',
  'damer',
  'dance',
  'danke',
  'danne',
  'danny',
  'danse',
  'dansk',
  'dario',
  'darts',
  'dates',
  'datra',
  'dauer',
  'dauym',
  'david',
  'davis',
  'daymn',
  'dayum',
  'ddude',
  'deale',
  'deals',
  'death',
  'debug',
  'debut',
  'decay',
  'decor',
  'deeet',
  'deger',
  'deira',
  'dekka',
  'dekke',
  'delay',
  'delen',
  'deler',
  'deles',
  'della',
  'delta',
  'delte',
  'demoe',
  'denen',
  'denge',
  'denna',
  'denne',
  'depoy',
  'derav',
  'deres',
  'derja',
  'derre',
  'derws',
  'desse',
  'dessv',
  'desto',
  'detta',
  'dette',
  'devin',
  'dicks',
  'diddi',
  'diego',
  'diett',
  'diffe',
  'digga',
  'digge',
  'digit',
  'dildo',
  'dimmu',
  'dingo',
  'dirra',
  'dirty',
  'disce',
  'disco',
  'discs',
  'disse',
  'ditch',
  'dixie',
  'dizze',
  'dizzy',
  'dnsen',
  'doble',
  'dodga',
  'dodgy',
  'doene',
  'doesn',
  'doing',
  'dokke',
  'dokku',
  'dolly',
  'domen',
  'donda',
  'donks',
  'donna',
  'donut',
  'doris',
  'dorsk',
  'dosen',
  'dosio',
  'doubt',
  'douro',
  'dovre',
  'downe',
  'draft',
  'drain',
  'drakk',
  'drakt',
  'dramm',
  'dratt',
  'draum',
  'dream',
  'dredd',
  'dreie',
  'dreit',
  'drepe',
  'drept',
  'dress',
  'drift',
  'drink',
  'drite',
  'driti',
  'dritt',
  'drive',
  'drone',
  'dropp',
  'drove',
  'drugs',
  'drunk',
  'druse',
  'drust',
  'dryge',
  'duden',
  'dudes',
  'dufte',
  'duger',
  'duker',
  'dukke',
  'dumma',
  'dummy',
  'dumpe',
  'dunka',
  'dunke',
  'dunno',
  'dupes',
  'duren',
  'durer',
  'dusje',
  'dutch',
  'duute',
  'dverg',
  'dybde',
  'dying',
  'dykar',
  'dylan',
  'dynga',
  'dynke',
  'dysle',
  'dytta',
  'dytte',
  'eagle',
  'early',
  'earth',
  'ecoer',
  'eddie',
  'edite',
  'edonk',
  'egget',
  'egner',
  'egnet',
  'egolf',
  'ehehe',
  'ehheh',
  'eides',
  'eiere',
  'eiger',
  'eight',
  'eigil',
  'eiket',
  'einar',
  'eirik',
  'ekkje',
  'ekorn',
  'eksem',
  'ekskl',
  'elatv',
  'elbil',
  'elden',
  'eldes',
  'eldre',
  'eldri',
  'eldst',
  'elekt',
  'elena',
  'elias',
  'elise',
  'elita',
  'elite',
  'ellen',
  'eller',
  'ellie',
  'elser',
  'elske',
  'elton',
  'elvis',
  'emacs',
  'empty',
  'ended',
  'enemy',
  'engel',
  'enhet',
  'enige',
  'eniro',
  'enjoy',
  'enkel',
  'enkle',
  'enorm',
  'ensom',
  'enten',
  'enter',
  'entry',
  'entur',
  'enums',
  'envis',
  'episk',
  'epler',
  'eplet',
  'epost',
  'error',
  'erter',
  'esker',
  'espen',
  'essay',
  'etfer',
  'ethic',
  'etikk',
  'etisk',
  'etter',
  'euros',
  'evans',
  'event',
  'every',
  'evita',
  'evjen',
  'evnen',
  'evner',
  'ewfew',
  'ework',
  'ewwfe',
  'exact',
  'excel',
  'exist',
  'exits',
  'extra',
  'faack',
  'faang',
  'faced',
  'facet',
  'facts',
  'fader',
  'faeen',
  'faget',
  'faila',
  'fails',
  'faith',
  'fakes',
  'fakta',
  'falle',
  'fallt',
  'false',
  'falsk',
  'famas',
  'famen',
  'famer',
  'fancy',
  'fanen',
  'faner',
  'fange',
  'fanto',
  'fanum',
  'faren',
  'farge',
  'farta',
  'farte',
  'fasen',
  'faser',
  'fasit',
  'faste',
  'fatet',
  'fatte',
  'fault',
  'feber',
  'fedme',
  'feede',
  'feels',
  'feige',
  'feile',
  'feira',
  'feire',
  'fejll',
  'felix',
  'fella',
  'femte',
  'ferie',
  'fersk',
  'festa',
  'feste',
  'fetch',
  'fette',
  'fettn',
  'fettt',
  'fever',
  'fewfe',
  'fiddl',
  'field',
  'fiffi',
  'fiffy',
  'fifty',
  'fight',
  'figma',
  'fiken',
  'fikle',
  'fiksa',
  'fikse',
  'filen',
  'filer',
  'files',
  'filma',
  'filme',
  'films',
  'final',
  'finna',
  'finne',
  'finns',
  'finse',
  'finsk',
  'fiona',
  'fiori',
  'firat',
  'fired',
  'fires',
  'firma',
  'first',
  'fiser',
  'fishy',
  'fiske',
  'fiste',
  'fitta',
  'fitte',
  'fives',
  'fixed',
  'fixen',
  'fixer',
  'fixes',
  'fixet',
  'fjeld',
  'fjell',
  'fjern',
  'fjong',
  'flagg',
  'flags',
  'flakk',
  'flaks',
  'flash',
  'flask',
  'flate',
  'flatt',
  'flaut',
  'flaws',
  'fleet',
  'flekk',
  'flere',
  'flesk',
  'flest',
  'flexe',
  'flink',
  'flock',
  'flokk',
  'flott',
  'flour',
  'flows',
  'fluer',
  'fluff',
  'flust',
  'flyer',
  'flyet',
  'flytt',
  'focha',
  'focus',
  'fokus',
  'folka',
  'folks',
  'follk',
  'fonna',
  'fonts',
  'fonzi',
  'foods',
  'foran',
  'forbi',
  'force',
  'fordi',
  'foret',
  'forke',
  'forma',
  'formo',
  'forms',
  'forno',
  'forte',
  'forum',
  'fosse',
  'fossi',
  'foten',
  'found',
  'foxtv',
  'fraga',
  'frakt',
  'franc',
  'frank',
  'frase',
  'freak',
  'fredo',
  'frees',
  'freia',
  'frekk',
  'frekt',
  'fresh',
  'fresr',
  'frick',
  'frida',
  'fried',
  'fries',
  'frigg',
  'frikk',
  'frise',
  'frisk',
  'frist',
  'frits',
  'fritt',
  'frode',
  'frodo',
  'front',
  'fruen',
  'fruit',
  'frukt',
  'fryse',
  'fryst',
  'fucka',
  'fuckd',
  'fucke',
  'fucks',
  'fucky',
  'fuckz',
  'fugle',
  'fulgt',
  'fulle',
  'fullt',
  'fully',
  'funds',
  'funka',
  'funke',
  'funky',
  'funne',
  'funni',
  'funny',
  'furry',
  'fuxka',
  'fuzzy',
  'fwdet',
  'fwwfe',
  'fyfan',
  'fyken',
  'fyker',
  'fylke',
  'fylla',
  'fylle',
  'fyllt',
  'fylte',
  'fyren',
  'fyrer',
  'fyret',
  'fyrte',
  'fysio',
  'gabel',
  'gagne',
  'galil',
  'galne',
  'gamel',
  'gamer',
  'games',
  'gamet',
  'gamez',
  'gamle',
  'ganga',
  'gange',
  'gangs',
  'ganke',
  'gantt',
  'garra',
  'gatas',
  'gaten',
  'gater',
  'gates',
  'gatti',
  'gaven',
  'gaver',
  'geare',
  'gebyr',
  'gecko',
  'geilo',
  'gener',
  'genie',
  'genom',
  'geoen',
  'geoip',
  'gerne',
  'gerry',
  'ghost',
  'giant',
  'gidde',
  'gikta',
  'gilde',
  'gimle',
  'gimli',
  'giphy',
  'girer',
  'giske',
  'gismo',
  'gitte',
  'given',
  'gives',
  'gizmo',
  'gjeld',
  'gjemt',
  'gjeng',
  'gjere',
  'gjest',
  'gjete',
  'gjord',
  'gjort',
  'glade',
  'glass',
  'glatt',
  'glede',
  'glemt',
  'glenn',
  'glets',
  'glide',
  'glimt',
  'glipp',
  'globe',
  'glory',
  'gmail',
  'gnell',
  'goals',
  'goden',
  'goder',
  'godis',
  'godta',
  'going',
  'golla',
  'gomaa',
  'gonna',
  'gonzo',
  'goood',
  'goooo',
  'goose',
  'gotem',
  'gotta',
  'grabs',
  'grace',
  'grade',
  'grail',
  'grams',
  'grand',
  'grape',
  'graph',
  'grass',
  'grats',
  'gratz',
  'gravd',
  'grave',
  'great',
  'green',
  'greet',
  'gregg',
  'greia',
  'greid',
  'greie',
  'greit',
  'grell',
  'greni',
  'gress',
  'greta',
  'grill',
  'grine',
  'grips',
  'grisa',
  'grodd',
  'gropa',
  'group',
  'grove',
  'grovt',
  'grunn',
  'gruse',
  'guard',
  'gubbe',
  'guero',
  'guess',
  'guest',
  'guffe',
  'guide',
  'gummi',
  'gunda',
  'gunde',
  'gunna',
  'gunne',
  'guten',
  'gutra',
  'gutta',
  'gvarv',
  'gylne',
  'gynne',
  'habbo',
  'hacka',
  'hacke',
  'hacks',
  'hacky',
  'hadde',
  'haddw',
  'hagen',
  'hager',
  'hahah',
  'hahha',
  'haien',
  'haken',
  'hakka',
  'hakke',
  'halen',
  'halla',
  'hallo',
  'halve',
  'halvt',
  'hamar',
  'hamza',
  'hande',
  'hands',
  'handy',
  'hanen',
  'hanke',
  'hanna',
  'hanne',
  'hansa',
  'happy',
  'harde',
  'hardt',
  'harem',
  'harry',
  'hashe',
  'hasle',
  'haste',
  'hater',
  'haven',
  'havet',
  'havin',
  'havna',
  'havne',
  'haxor',
  'heads',
  'heard',
  'heart',
  'heavy',
  'hebbe',
  'hedge',
  'heelt',
  'hefte',
  'heheh',
  'heidi',
  'heier',
  'heile',
  'heilt',
  'heime',
  'hekta',
  'heldt',
  'helga',
  'helge',
  'hella',
  'helle',
  'hello',
  'hellu',
  'helps',
  'helsa',
  'helse',
  'helst',
  'hemit',
  'henda',
  'hende',
  'henge',
  'hengt',
  'henne',
  'henry',
  'henta',
  'hente',
  'herbs',
  'herer',
  'herje',
  'herme',
  'herpa',
  'herre',
  'hersk',
  'heten',
  'heter',
  'hetta',
  'heure',
  'hevet',
  'heydu',
  'heyoo',
  'hides',
  'hills',
  'hilse',
  'hisse',
  'hitte',
  'hiver',
  'hivju',
  'hjalo',
  'hjalp',
  'hjelm',
  'hjelp',
  'hmmmm',
  'hoang',
  'hobby',
  'hoder',
  'hodet',
  'hodor',
  'hofta',
  'hofte',
  'hogga',
  'hoity',
  'holde',
  'holdt',
  'holes',
  'holst',
  'holte',
  'hooch',
  'hooka',
  'hooke',
  'hoppe',
  'horer',
  'horse',
  'hosta',
  'hoste',
  'hosts',
  'hotel',
  'hound',
  'hours',
  'house',
  'hover',
  'https',
  'huben',
  'huden',
  'hugge',
  'hugse',
  'huker',
  'hukes',
  'huket',
  'hulls',
  'human',
  'humio',
  'humle',
  'humor',
  'hunde',
  'húrin',
  'hurra',
  'huset',
  'huska',
  'huske',
  'husky',
  'husly',
  'huuuh',
  'hvalp',
  'hvelv',
  'hvert',
  'hvile',
  'hvist',
  'hvite',
  'hvitt',
  'hydra',
  'hylla',
  'hylle',
  'hypea',
  'hyped',
  'hypen',
  'hyper',
  'hypet',
  'hypre',
  'hytta',
  'hytte',
  'icons',
  'ideal',
  'ideas',
  'ideen',
  'ideer',
  'idgaf',
  'idiot',
  'ifftt',
  'ifjor',
  'ifttt',
  'igang',
  'igien',
  'igjen',
  'ihjel',
  'iikke',
  'ikita',
  'ikker',
  'ikkje',
  'ilden',
  'illus',
  'ilyas',
  'image',
  'imens',
  'imgur',
  'impro',
  'inatt',
  'inbox',
  'incur',
  'inder',
  'index',
  'india',
  'indie',
  'indre',
  'ingen',
  'inger',
  'innad',
  'innan',
  'innbo',
  'innen',
  'inner',
  'innit',
  'innom',
  'innse',
  'input',
  'inria',
  'insja',
  'inspo',
  'insta',
  'intel',
  'intim',
  'intro',
  'inver',
  'ioniq',
  'irina',
  'irish',
  'irony',
  'isaac',
  'isbit',
  'issue',
  'istad',
  'italy',
  'items',
  'iterm',
  'itpro',
  'ivana',
  'ivrig',
  'izadi',
  'izzie',
  'jaaaa',
  'jacob',
  'jadda',
  'jager',
  'jakke',
  'jakob',
  'jakta',
  'jakte',
  'jalla',
  'james',
  'jamie',
  'janky',
  'janne',
  'jason',
  'jeans',
  'jeeeu',
  'jeger',
  'jekke',
  'jelly',
  'jenna',
  'jenny',
  'jenta',
  'jente',
  'jeppe',
  'jeppp',
  'jepps',
  'jepsi',
  'jerne',
  'jerro',
  'jesus',
  'jette',
  'jetty',
  'jevnt',
  'jezus',
  'jinxa',
  'jippi',
  'jizus',
  'jizza',
  'jobba',
  'jobbe',
  'joccy',
  'jogge',
  'johan',
  'joina',
  'joine',
  'joint',
  'joker',
  'jokes',
  'jomez',
  'jonas',
  'jones',
  'jorde',
  'jotte',
  'jsdoc',
  'jsonb',
  'judge',
  'juice',
  'juicy',
  'juksa',
  'julen',
  'jules',
  'julie',
  'jumbo',
  'junit',
  'jusen',
  'juvet',
  'kaaan',
  'kaare',
  'kabal',
  'kabel',
  'kaffe',
  'kaffi',
  'kafka',
  'kaisa',
  'kaiza',
  'kajsa',
  'kaken',
  'kalde',
  'kaldt',
  'kalix',
  'kalla',
  'kalle',
  'kalte',
  'kanal',
  'kanel',
  'kanin',
  'kanke',
  'kanna',
  'kanne',
  'kanon',
  'kansj',
  'kansk',
  'kanye',
  'kappa',
  'kappe',
  'kapre',
  'karen',
  'karma',
  'karos',
  'karpe',
  'kaset',
  'kasko',
  'kassa',
  'kasse',
  'kasta',
  'kaste',
  'katia',
  'katta',
  'katte',
  'kaysa',
  'kebab',
  'keene',
  'keily',
  'keita',
  'kenny',
  'kenya',
  'kenzo',
  'keras',
  'kerid',
  'kerry',
  'keste',
  'kevin',
  'kiara',
  'kicka',
  'kicke',
  'kicks',
  'kiden',
  'kidsa',
  'kiely',
  'kiisa',
  'kikka',
  'kikke',
  'kikki',
  'kilda',
  'kilde',
  'kille',
  'kills',
  'kilos',
  'kimse',
  'kinds',
  'kings',
  'kinky',
  'kiosk',
  'kirke',
  'kitta',
  'kjapp',
  'kjapt',
  'kjeda',
  'kjede',
  'kjeft',
  'kjekk',
  'kjeks',
  'kjekt',
  'kjell',
  'kjent',
  'kjepp',
  'kjipe',
  'kjipt',
  'klage',
  'klapp',
  'klara',
  'klare',
  'klart',
  'kledd',
  'klein',
  'klikk',
  'klipp',
  'kliss',
  'kloke',
  'klone',
  'klovn',
  'klpet',
  'klubb',
  'kluss',
  'klype',
  'knakk',
  'knall',
  'knapp',
  'knapt',
  'kneet',
  'knekt',
  'knife',
  'known',
  'knows',
  'knurr',
  'knuse',
  'knust',
  'knytt',
  'koala',
  'kobla',
  'koble',
  'koden',
  'koder',
  'kodet',
  'kohts',
  'koivo',
  'koken',
  'koker',
  'kokos',
  'kokte',
  'kolli',
  'kolon',
  'kombi',
  'kombo',
  'komda',
  'komle',
  'komma',
  'komme',
  'konge',
  'konte',
  'konti',
  'konto',
  'koppa',
  'koppe',
  'korea',
  'korka',
  'korte',
  'kosen',
  'koser',
  'koses',
  'kosta',
  'koste',
  'kraft',
  'krakk',
  'krast',
  'kreft',
  'kremt',
  'kreta',
  'krets',
  'kreve',
  'krise',
  'krita',
  'kroka',
  'krone',
  'kropp',
  'krutt',
  'krymp',
  'krype',
  'kryss',
  'kudos',
  'kuken',
  'kuker',
  'kuket',
  'kulde',
  'kuler',
  'kulle',
  'kunde',
  'kunne',
  'kunst',
  'kurre',
  'kurve',
  'kurwa',
  'kutta',
  'kutte',
  'kuung',
  'kvale',
  'kvalm',
  'kvalp',
  'kvart',
  'kveld',
  'kvele',
  'kvier',
  'kvikk',
  'kvite',
  'kvitt',
  'labbe',
  'label',
  'laben',
  'laber',
  'lader',
  'ladet',
  'lafon',
  'lagde',
  'lager',
  'lages',
  'laget',
  'lagga',
  'laggy',
  'lagra',
  'lagre',
  'lahsa',
  'laika',
  'laken',
  'lakka',
  'lamda',
  'lamme',
  'lampa',
  'landa',
  'lande',
  'lands',
  'lanes',
  'lange',
  'langs',
  'langt',
  'lanka',
  'large',
  'larve',
  'laser',
  'lasse',
  'lasta',
  'laste',
  'later',
  'latta',
  'lauch',
  'laust',
  'layer',
  'ldquo',
  'leads',
  'learn',
  'least',
  'leave',
  'leder',
  'ledet',
  'ledig',
  'leela',
  'legal',
  'legen',
  'legga',
  'legge',
  'legit',
  'leide',
  'leier',
  'leies',
  'leika',
  'leike',
  'leite',
  'leken',
  'leker',
  'lekne',
  'lekte',
  'lemon',
  'lempe',
  'lende',
  'lenge',
  'lenke',
  'leroy',
  'leser',
  'leste',
  'lesto',
  'leter',
  'lette',
  'levde',
  'level',
  'lever',
  'lewis',
  'lexie',
  'lhasa',
  'libra',
  'lider',
  'ligge',
  'light',
  'ligma',
  'ligna',
  'ligne',
  'liker',
  'likes',
  'likja',
  'likne',
  'likte',
  'lille',
  'lilli',
  'lilly',
  'limer',
  'limit',
  'limmy',
  'linda',
  'liner',
  'lines',
  'lingo',
  'linja',
  'linje',
  'linka',
  'linke',
  'links',
  'linta',
  'linus',
  'linux',
  'lissi',
  'lista',
  'liste',
  'lists',
  'liten',
  'liter',
  'litta',
  'littt',
  'lived',
  'lives',
  'livet',
  'livin',
  'lizzi',
  'lizzy',
  'ljuge',
  'llitt',
  'loads',
  'lobby',
  'local',
  'locka',
  'locks',
  'lodde',
  'lodge',
  'logga',
  'logge',
  'logic',
  'login',
  'logon',
  'lojal',
  'lokal',
  'lokke',
  'lomma',
  'lomme',
  'lompe',
  'looks',
  'loool',
  'lopez',
  'loppe',
  'lords',
  'losby',
  'losji',
  'lossy',
  'lotta',
  'lotte',
  'louie',
  'loury',
  'loved',
  'loven',
  'lover',
  'lovet',
  'lovte',
  'lower',
  'ltfor',
  'lucas',
  'lucky',
  'ludde',
  'luffe',
  'lufta',
  'lufte',
  'lukas',
  'luker',
  'lukes',
  'luket',
  'lukka',
  'lukke',
  'lukta',
  'lukte',
  'lulle',
  'lunch',
  'lunde',
  'lunge',
  'lunne',
  'lunsj',
  'lupen',
  'lurer',
  'lurte',
  'lyche',
  'lyden',
  'lyder',
  'lydig',
  'lying',
  'lykke',
  'lynne',
  'lyser',
  'lyset',
  'lytte',
  'macen',
  'macer',
  'macho',
  'macon',
  'macos',
  'macro',
  'maero',
  'mafia',
  'magen',
  'mager',
  'magga',
  'maggy',
  'magic',
  'magne',
  'maiko',
  'mails',
  'mailz',
  'major',
  'makan',
  'maken',
  'maker',
  'makes',
  'makkr',
  'makro',
  'maksa',
  'malen',
  'maler',
  'malle',
  'malts',
  'mamba',
  'mamma',
  'mando',
  'maner',
  'manet',
  'mange',
  'mango',
  'mangt',
  'manko',
  'mapen',
  'mappa',
  'mappe',
  'march',
  'marco',
  'maren',
  'maria',
  'marie',
  'mario',
  'marka',
  'marks',
  'marle',
  'marte',
  'maser',
  'maske',
  'masse',
  'maste',
  'match',
  'maten',
  'mater',
  'mates',
  'matte',
  'maxer',
  'maybe',
  'mayen',
  'mayne',
  'mbpen',
  'mcash',
  'mdash',
  'means',
  'meant',
  'medal',
  'media',
  'medio',
  'medle',
  'medom',
  'meeen',
  'meets',
  'meget',
  'mekka',
  'mekke',
  'melde',
  'meldt',
  'melis',
  'melon',
  'memes',
  'mener',
  'menes',
  'menig',
  'mente',
  'menus',
  'merch',
  'merga',
  'merge',
  'merka',
  'merke',
  'merkt',
  'merry',
  'messa',
  'messe',
  'messy',
  'meste',
  'metal',
  'meter',
  'metoo',
  'mette',
  'meyer',
  'micen',
  'micke',
  'micky',
  'micro',
  'midge',
  'might',
  'mikes',
  'mikey',
  'mikke',
  'mikki',
  'mikro',
  'milan',
  'mildt',
  'miles',
  'milka',
  'mille',
  'mímir',
  'mimmi',
  'minde',
  'minds',
  'miner',
  'minet',
  'minge',
  'minke',
  'minne',
  'minni',
  'minor',
  'minst',
  'minte',
  'minus',
  'mirai',
  'mirra',
  'missa',
  'misse',
  'missy',
  'mista',
  'miste',
  'mixed',
  'mixen',
  'mkbhd',
  'mkdir',
  'mmyes',
  'mnden',
  'mnder',
  'mobil',
  'mocha',
  'modal',
  'modde',
  'model',
  'modem',
  'moder',
  'modes',
  'modig',
  'modne',
  'modul',
  'modus',
  'moist',
  'molde',
  'molly',
  'molti',
  'mommy',
  'money',
  'mongo',
  'monte',
  'month',
  'monty',
  'moral',
  'moren',
  'morgo',
  'moroa',
  'morra',
  'morro',
  'morse',
  'morum',
  'mosel',
  'moses',
  'motiv',
  'motor',
  'motta',
  'motto',
  'mouse',
  'moved',
  'moves',
  'moyai',
  'msdos',
  'mshed',
  'muffi',
  'muffy',
  'muleg',
  'mulig',
  'multi',
  'mumbo',
  'munnf',
  'music',
  'musti',
  'mutes',
  'mutet',
  'myhre',
  'mykje',
  'mynde',
  'mysql',
  'myten',
  'myter',
  'naaah',
  'naass',
  'nades',
  'nadja',
  'naija',
  'naila',
  'naile',
  'nalle',
  'named',
  'namen',
  'nando',
  'nanny',
  'nappe',
  'narmo',
  'narne',
  'nasty',
  'nasus',
  'natta',
  'natur',
  'navna',
  'naxos',
  'naysh',
  'nbare',
  'nbest',
  'nblir',
  'nboom',
  'ncall',
  'ncase',
  'ncody',
  'ncome',
  'ncons',
  'ncsgo',
  'ncurl',
  'ndaen',
  'ndash',
  'ndata',
  'ndate',
  'ndelt',
  'ndeno',
  'ndere',
  'ndesc',
  'ndiff',
  'nditt',
  'ndock',
  'ndone',
  'ndoom',
  'nduja',
  'ndyld',
  'neala',
  'nebit',
  'necho',
  'nedit',
  'nedre',
  'needs',
  'nehmm',
  'neida',
  'nekte',
  'nelly',
  'nemid',
  'nenig',
  'nenum',
  'nepal',
  'neppe',
  'nergo',
  'nerve',
  'nesen',
  'neste',
  'nette',
  'netto',
  'neven',
  'never',
  'nevne',
  'nevnt',
  'newbs',
  'newly',
  'nexus',
  'nfall',
  'nfant',
  'nfikk',
  'nfinn',
  'nfint',
  'nflip',
  'nfred',
  'nfrom',
  'nfull',
  'nfunc',
  'nginx',
  'ngrok',
  'nhaha',
  'nhann',
  'nhead',
  'nhele',
  'nhelt',
  'nhere',
  'nhopp',
  'nhost',
  'nhttp',
  'nhuge',
  'nhusk',
  'nhvem',
  'nhvis',
  'nhvor',
  'nicet',
  'niche',
  'nicki',
  'nicks',
  'nicky',
  'night',
  'niice',
  'nikka',
  'nikke',
  'nikki',
  'nilla',
  'nimpl',
  'ninja',
  'ninni',
  'ninth',
  'nisje',
  'nisse',
  'niste',
  'nitro',
  'njaaa',
  'njahn',
  'njdch',
  'njepp',
  'njoin',
  'njuli',
  'njuni',
  'nkeep',
  'nkern',
  'nkimi',
  'nkira',
  'nknut',
  'nkode',
  'nkort',
  'nlage',
  'nlars',
  'nleft',
  'nleia',
  'nleie',
  'nleif',
  'nlike',
  'nlink',
  'nlita',
  'nlitt',
  'nlong',
  'nlook',
  'nluna',
  'nmade',
  'nmail',
  'nmake',
  'nmaya',
  'nmbui',
  'nmekk',
  'nmerk',
  'nmest',
  'nmike',
  'nmine',
  'nmira',
  'nmove',
  'nmuch',
  'nmyre',
  'nnavn',
  'nnice',
  'nnmap',
  'nnoen',
  'nnytt',
  'nobel',
  'noddy',
  'noden',
  'noder',
  'nodes',
  'nodez',
  'nodin',
  'noens',
  'noget',
  'noice',
  'nokas',
  'nokka',
  'nokon',
  'nokre',
  'nomme',
  'noobs',
  'nooes',
  'noooo',
  'noppe',
  'noreg',
  'norge',
  'norse',
  'norsk',
  'north',
  'notat',
  'notch',
  'notes',
  'notis',
  'nouns',
  'novel',
  'npark',
  'npass',
  'npmjs',
  'npolo',
  'nport',
  'npris',
  'nprod',
  'npush',
  'nrakk',
  'nrase',
  'nread',
  'nreal',
  'nreol',
  'nrett',
  'nring',
  'nsamt',
  'nsatt',
  'nselv',
  'nsend',
  'nshit',
  'nsita',
  'nskal',
  'nsker',
  'nskje',
  'nslik',
  'nstem',
  'nstor',
  'nsudo',
  'nsurl',
  'nsykt',
  'nsyns',
  'ntakk',
  'ntatt',
  'nteam',
  'ntest',
  'nthat',
  'nthen',
  'nthey',
  'nthis',
  'nthor',
  'ntika',
  'ntime',
  'nting',
  'ntldr',
  'ntnus',
  'ntror',
  'ntroy',
  'ntype',
  'nudge',
  'nudie',
  'nukas',
  'nuker',
  'nused',
  'nuser',
  'nvant',
  'nvelg',
  'nvery',
  'nvoss',
  'nwhat',
  'nwith',
  'nybil',
  'nydal',
  'nyere',
  'nyhet',
  'nylig',
  'nyter',
  'nytes',
  'nytte',
  'oageo',
  'oasis',
  'oblad',
  'ocaml',
  'occur',
  'ocean',
  'odile',
  'offer',
  'ofres',
  'ofret',
  'often',
  'ohhhh',
  'olavs',
  'oljen',
  'oljet',
  'olsen',
  'omega',
  'omeng',
  'omvei',
  'ongar',
  'onkel',
  'oooof',
  'oooog',
  'ooooh',
  'oouhh',
  'oouuh',
  'ophus',
  'oppgi',
  'oppom',
  'opter',
  'optes',
  'optet',
  'orbit',
  'ordan',
  'orden',
  'order',
  'ordet',
  'ordna',
  'ordne',
  'ordre',
  'orgen',
  'orgnr',
  'origo',
  'orion',
  'orkas',
  'orker',
  'orket',
  'oscar',
  'osebx',
  'osita',
  'oskar',
  'oslos',
  'osten',
  'oster',
  'other',
  'ouchy',
  'oufff',
  'ought',
  'ouuff',
  'overp',
  'overs',
  'ovnen',
  'owinn',
  'owner',
  'padel',
  'pades',
  'pager',
  'pages',
  'paint',
  'paire',
  'pairs',
  'pakka',
  'pakke',
  'paleo',
  'panda',
  'panel',
  'panes',
  'panic',
  'panna',
  'pante',
  'paper',
  'papir',
  'pappa',
  'param',
  'parat',
  'paris',
  'parse',
  'parte',
  'parti',
  'parts',
  'party',
  'parvo',
  'passa',
  'passe',
  'pasta',
  'paste',
  'patch',
  'paths',
  'pause',
  'payer',
  'payet',
  'peace',
  'peach',
  'pearl',
  'peasy',
  'peder',
  'pedro',
  'peeks',
  'peile',
  'peker',
  'pekes',
  'pekte',
  'pelle',
  'penga',
  'penge',
  'penis',
  'pepsi',
  'perks',
  'perla',
  'perle',
  'pesto',
  'peter',
  'petra',
  'petty',
  'pffft',
  'phett',
  'phone',
  'photo',
  'piano',
  'picka',
  'picke',
  'picks',
  'piece',
  'piffe',
  'pilen',
  'piler',
  'pille',
  'pilot',
  'pilse',
  'pimpe',
  'pinch',
  'pinen',
  'pinge',
  'pinky',
  'pinne',
  'pinot',
  'pipen',
  'piper',
  'pipes',
  'pippa',
  'pippi',
  'pirke',
  'pissa',
  'pisse',
  'pitch',
  'pivot',
  'pixel',
  'pizza',
  'pizzz',
  'pjusk',
  'pkill',
  'place',
  'plagg',
  'plain',
  'plana',
  'plans',
  'plant',
  'plass',
  'plast',
  'plays',
  'pledd',
  'pleid',
  'pleie',
  'pluss',
  'pluto',
  'poden',
  'poeng',
  'point',
  'poker',
  'poket',
  'polen',
  'polet',
  'polly',
  'polpa',
  'ponni',
  'ponzi',
  'pools',
  'poppe',
  'poppy',
  'popup',
  'porno',
  'porte',
  'ports',
  'poser',
  'posta',
  'poste',
  'posts',
  'poter',
  'potet',
  'pouch',
  'power',
  'pracc',
  'prank',
  'prata',
  'prate',
  'prege',
  'prene',
  'prepp',
  'press',
  'price',
  'prikk',
  'prima',
  'prime',
  'princ',
  'prins',
  'print',
  'prior',
  'prise',
  'probz',
  'proff',
  'proof',
  'props',
  'proto',
  'proud',
  'prove',
  'proxy',
  'prute',
  'psuen',
  'psyke',
  'puket',
  'pulle',
  'pulls',
  'pulse',
  'pumpe',
  'punch',
  'punge',
  'punkt',
  'punto',
  'purge',
  'purra',
  'purre',
  'pusen',
  'puser',
  'pusha',
  'pushe',
  'pusse',
  'pussy',
  'puste',
  'puter',
  'putin',
  'putta',
  'putte',
  'putts',
  'pwner',
  'pwnet',
  'pyser',
  'pyton',
  'qatar',
  'quads',
  'quala',
  'queen',
  'query',
  'quest',
  'queue',
  'quick',
  'quiet',
  'quite',
  'quito',
  'qunta',
  'qvams',
  'racer',
  'races',
  'radar',
  'raden',
  'rader',
  'radio',
  'radon',
  'ragen',
  'raide',
  'rails',
  'raise',
  'rakne',
  'rally',
  'rambo',
  'ramla',
  'ramme',
  'rampe',
  'randi',
  'rando',
  'ranet',
  'range',
  'rapen',
  'rapid',
  'rappi',
  'rasen',
  'raser',
  'rasha',
  'raska',
  'raske',
  'raskt',
  'raspe',
  'rated',
  'raten',
  'rates',
  'ratet',
  'ratio',
  'raust',
  'rdquo',
  'reach',
  'react',
  'ready',
  'realm',
  'realt',
  'recap',
  'redda',
  'redde',
  'redir',
  'redis',
  'redux',
  'reeee',
  'reell',
  'reelt',
  'regel',
  'regga',
  'regge',
  'regna',
  'regne',
  'regnr',
  'rehab',
  'reise',
  'reist',
  'reisz',
  'reite',
  'reker',
  'rekke',
  'relle',
  'renew',
  'renne',
  'rense',
  'rente',
  'reply',
  'repos',
  'rerik',
  'reser',
  'reset',
  'rethy',
  'retro',
  'retry',
  'retta',
  'rette',
  'retur',
  'reven',
  'revet',
  'revil',
  'rhina',
  'rhone',
  'ribbe',
  'ricco',
  'ricky',
  'ridge',
  'rifla',
  'rifle',
  'rigga',
  'rigge',
  'right',
  'riket',
  'rikke',
  'rikko',
  'rilke',
  'rimme',
  'ringe',
  'ringo',
  'ringt',
  'rioja',
  'risky',
  'rival',
  'river',
  'roald',
  'roast',
  'robin',
  'robot',
  'rocky',
  'rogan',
  'roger',
  'rogfk',
  'rogue',
  'roids',
  'roleg',
  'rolex',
  'rolig',
  'rolle',
  'romma',
  'rompa',
  'ronco',
  'ronja',
  'ronny',
  'rooms',
  'ropte',
  'roret',
  'rosen',
  'roser',
  'rossi',
  'roten',
  'roter',
  'rotet',
  'rough',
  'round',
  'route',
  'roxie',
  'royal',
  'rsquo',
  'ruben',
  'rufus',
  'ruled',
  'ruler',
  'rules',
  'rulla',
  'rulle',
  'rumpa',
  'runar',
  'runde',
  'rundt',
  'rushe',
  'rusle',
  'rustc',
  'rusty',
  'ruter',
  'rutes',
  'rutta',
  'rydda',
  'rydde',
  'ryker',
  'rykka',
  'rykke',
  'ryper',
  'ryzen',
  'saber',
  'sabla',
  'sable',
  'sabre',
  'sadaf',
  'sadly',
  'safer',
  'saint',
  'saken',
  'saker',
  'sakte',
  'saldo',
  'salen',
  'sales',
  'salgs',
  'salgt',
  'salte',
  'samen',
  'samla',
  'samle',
  'samma',
  'samme',
  'sammy',
  'sandi',
  'sanka',
  'sanke',
  'sankt',
  'sanna',
  'sanne',
  'santa',
  'santo',
  'sarah',
  'saroj',
  'sasha',
  'sashi',
  'sassy',
  'satan',
  'satse',
  'satte',
  'sauce',
  'sauer',
  'sauna',
  'saver',
  'saves',
  'saxon',
  'scala',
  'scale',
  'scary',
  'scena',
  'scene',
  'scope',
  'score',
  'scott',
  'scout',
  'scrap',
  'scrum',
  'sdken',
  'seata',
  'seats',
  'seffa',
  'segur',
  'seier',
  'seigt',
  'seine',
  'seint',
  'selbu',
  'selen',
  'selge',
  'selma',
  'selve',
  'semen',
  'sende',
  'sendt',
  'sener',
  'senga',
  'senka',
  'senke',
  'senor',
  'señor',
  'sense',
  'senta',
  'serde',
  'serie',
  'serif',
  'serve',
  'seter',
  'sette',
  'settr',
  'setup',
  'seven',
  'shaby',
  'shady',
  'shafi',
  'shait',
  'shana',
  'shani',
  'shape',
  'shara',
  'share',
  'shari',
  'shark',
  'sharp',
  'shart',
  'shaun',
  'shave',
  'sheep',
  'sheet',
  'shell',
  'shena',
  'shera',
  'shiat',
  'shiba',
  'shiet',
  'shift',
  'shiit',
  'shila',
  'shina',
  'shine',
  'shira',
  'shirt',
  'shite',
  'shits',
  'shitt',
  'shmem',
  'shoot',
  'shops',
  'short',
  'shots',
  'shout',
  'showe',
  'shown',
  'shows',
  'shrug',
  'sicke',
  'sidan',
  'sidem',
  'siden',
  'sider',
  'sidne',
  'siege',
  'sigen',
  'sigge',
  'siggy',
  'signa',
  'signe',
  'sigve',
  'siiii',
  'sikra',
  'sikre',
  'sikta',
  'sikte',
  'silje',
  'simba',
  'simen',
  'simmy',
  'sinan',
  'since',
  'sindy',
  'sings',
  'sinna',
  'sinne',
  'sinns',
  'sinus',
  'sirup',
  'siste',
  'sitat',
  'siter',
  'sites',
  'sitte',
  'sitti',
  'siver',
  'sizen',
  'sizes',
  'sjakk',
  'sjalu',
  'sjans',
  'sjapp',
  'sjarm',
  'sjefe',
  'sjekk',
  'sjela',
  'sjele',
  'sjenk',
  'sjens',
  'sjokk',
  'sjuke',
  'sjukt',
  'skada',
  'skadd',
  'skade',
  'skala',
  'skall',
  'skape',
  'skapt',
  'skarp',
  'skatt',
  'skeis',
  'skien',
  'skier',
  'skill',
  'skilt',
  'skint',
  'skjed',
  'skjer',
  'skjet',
  'skjul',
  'skled',
  'sklei',
  'sklir',
  'skoda',
  'skoen',
  'skogs',
  'skole',
  'skrev',
  'skrik',
  'skriv',
  'skrot',
  'skrue',
  'skrur',
  'skrus',
  'skryt',
  'skudd',
  'skuff',
  'skule',
  'skull',
  'skurk',
  'skuta',
  'skutt',
  'skyen',
  'skyld',
  'skyll',
  'skype',
  'skyte',
  'skyve',
  'slack',
  'slags',
  'slakt',
  'slang',
  'slank',
  'slant',
  'slapp',
  'slaya',
  'slaye',
  'sleep',
  'sleit',
  'slekt',
  'sleng',
  'slett',
  'slice',
  'slick',
  'slide',
  'slike',
  'slikk',
  'slikt',
  'slipp',
  'slite',
  'slitt',
  'slogs',
  'slope',
  'slott',
  'sludd',
  'slukt',
  'slush',
  'slutt',
  'smake',
  'smaks',
  'smakt',
  'small',
  'smalt',
  'smart',
  'smell',
  'smelt',
  'smewd',
  'smile',
  'smirk',
  'smisk',
  'smite',
  'smitt',
  'smoke',
  'smsen',
  'smser',
  'smula',
  'smule',
  'smurf',
  'smuud',
  'snack',
  'snake',
  'snakk',
  'snaps',
  'snart',
  'snaut',
  'sneak',
  'sneen',
  'snike',
  'sniky',
  'snill',
  'snilt',
  'sning',
  'snitt',
  'snoke',
  'snoob',
  'snork',
  'snsrt',
  'snudd',
  'snuse',
  'snuta',
  'snute',
  'snutt',
  'snytt',
  'sofan',
  'sofie',
  'sofus',
  'sogte',
  'solan',
  'solen',
  'solgt',
  'solid',
  'solli',
  'solon',
  'solte',
  'solve',
  'sonde',
  'soner',
  'sonic',
  'sonny',
  'sonos',
  'sonys',
  'sophi',
  'sopra',
  'sorry',
  'sorte',
  'sound',
  'south',
  'sover',
  'sovna',
  'space',
  'spain',
  'spant',
  'spare',
  'spart',
  'spawn',
  'speak',
  'specc',
  'specs',
  'speed',
  'spela',
  'spele',
  'spell',
  'spend',
  'spenn',
  'spent',
  'spess',
  'spets',
  'spice',
  'spicy',
  'spike',
  'spill',
  'spilt',
  'spise',
  'spiss',
  'spist',
  'spitz',
  'split',
  'spmet',
  'spock',
  'spole',
  'spons',
  'spore',
  'sport',
  'spots',
  'sprek',
  'sprer',
  'spres',
  'sprik',
  'sprit',
  'spurt',
  'spurv',
  'spydd',
  'spylt',
  'squad',
  'squid',
  'srole',
  'stack',
  'staff',
  'stage',
  'staka',
  'stake',
  'stakk',
  'stall',
  'stand',
  'stang',
  'stars',
  'start',
  'stash',
  'state',
  'stats',
  'staus',
  'staut',
  'stave',
  'stays',
  'steak',
  'steal',
  'steam',
  'stede',
  'steel',
  'steen',
  'steff',
  'steig',
  'stein',
  'steke',
  'stekt',
  'stell',
  'stemt',
  'stend',
  'steps',
  'sterk',
  'steve',
  'stews',
  'stian',
  'stick',
  'stien',
  'stige',
  'stikk',
  'still',
  'stilt',
  'stine',
  'sting',
  'stink',
  'stive',
  'stivt',
  'stjal',
  'stjel',
  'stock',
  'stoff',
  'stokk',
  'stole',
  'stolt',
  'stonk',
  'stopp',
  'stora',
  'store',
  'storm',
  'stort',
  'story',
  'stout',
  'strak',
  'stram',
  'strat',
  'strax',
  'stray',
  'strid',
  'strih',
  'strip',
  'strix',
  'stryk',
  'stuck',
  'study',
  'stuen',
  'stuer',
  'stuff',
  'stund',
  'stunt',
  'stupe',
  'stygt',
  'stykk',
  'styla',
  'style',
  'styre',
  'styrt',
  'styve',
  'sucks',
  'sugde',
  'sugen',
  'suger',
  'suges',
  'sugne',
  'suite',
  'summa',
  'sunne',
  'sunny',
  'suoix',
  'super',
  'suppi',
  'supre',
  'susen',
  'sushi',
  'sutcu',
  'svaie',
  'svake',
  'svakt',
  'svare',
  'svart',
  'sveen',
  'svein',
  'sveip',
  'svert',
  'svett',
  'sveve',
  'svidd',
  'svikt',
  'sving',
  'svipp',
  'swarm',
  'sweat',
  'sweet',
  'swift',
  'swipe',
  'syden',
  'sykel',
  'sykla',
  'sykle',
  'synce',
  'synes',
  'synet',
  'synge',
  'synst',
  'syrah',
  'syver',
  'szizu',
  'tabbe',
  'taben',
  'table',
  'tacky',
  'tacos',
  'tagge',
  'taiga',
  'taiko',
  'taile',
  'taken',
  'takes',
  'taket',
  'takke',
  'takla',
  'takle',
  'takst',
  'takta',
  'taler',
  'talet',
  'talks',
  'tamme',
  'tanja',
  'tanke',
  'tante',
  'taper',
  'tapet',
  'tapte',
  'tards',
  'tarje',
  'tarly',
  'taron',
  'tasks',
  'tasle',
  'taste',
  'tasty',
  'tavle',
  'taxes',
  'tbana',
  'tbane',
  'teach',
  'teame',
  'teams',
  'teamz',
  'tears',
  'teddy',
  'tegne',
  'teikn',
  'teiko',
  'teipe',
  'teken',
  'tekna',
  'tekst',
  'telia',
  'telle',
  'tells',
  'telte',
  'tempo',
  'tende',
  'tends',
  'tenet',
  'tenke',
  'tenkt',
  'tenna',
  'tenor',
  'teori',
  'teppe',
  'terch',
  'terje',
  'terms',
  'tesla',
  'tessa',
  'tessy',
  'testa',
  'teste',
  'tests',
  'teten',
  'tetta',
  'tette',
  'tetur',
  'texas',
  'thang',
  'thank',
  'thats',
  'theft',
  'their',
  'theme',
  'there',
  'these',
  'theva',
  'thiel',
  'thigh',
  'thing',
  'think',
  'third',
  'those',
  'three',
  'throw',
  'thula',
  'tiara',
  'tibbe',
  'tibby',
  'ticko',
  'tiden',
  'tider',
  'tiffi',
  'tiger',
  'tigge',
  'tight',
  'tikka',
  'tikke',
  'tilby',
  'tiles',
  'tilla',
  'timed',
  'timen',
  'timer',
  'times',
  'timet',
  'timmi',
  'timmy',
  'tinka',
  'tiper',
  'tippa',
  'tippe',
  'tippo',
  'tipsa',
  'tipse',
  'tired',
  'tispa',
  'tispe',
  'tisse',
  'titan',
  'title',
  'titte',
  'titti',
  'tjena',
  'tjene',
  'tjent',
  'tjern',
  'tjora',
  'tkopi',
  'tlfen',
  'tlfnr',
  'tobey',
  'today',
  'todos',
  'toget',
  'toggl',
  'toity',
  'token',
  'tokio',
  'tokyo',
  'tolga',
  'tolke',
  'tomat',
  'tomme',
  'tommi',
  'tommy',
  'tomta',
  'tonen',
  'toner',
  'tonic',
  'tonig',
  'tonje',
  'tools',
  'tooth',
  'topsy',
  'torje',
  'torro',
  'torsk',
  'toska',
  'tosql',
  'tossa',
  'total',
  'toten',
  'totes',
  'touch',
  'tough',
  'tower',
  'toxic',
  'trace',
  'track',
  'tracy',
  'trade',
  'traff',
  'train',
  'trait',
  'trakk',
  'trapp',
  'trash',
  'trass',
  'treat',
  'trees',
  'treff',
  'trege',
  'tregt',
  'treig',
  'trekk',
  'trekt',
  'trene',
  'treng',
  'trent',
  'trett',
  'trial',
  'tribe',
  'tried',
  'trikk',
  'triks',
  'trinn',
  'trips',
  'trisk',
  'trist',
  'trodd',
  'troen',
  'trois',
  'troja',
  'troll',
  'trond',
  'troor',
  'tross',
  'trots',
  'troya',
  'troys',
  'truck',
  'truet',
  'truly',
  'trump',
  'trust',
  'trygg',
  'trygt',
  'trykk',
  'trykt',
  'tskal',
  'tuber',
  'tudos',
  'tukle',
  'tulip',
  'tulla',
  'tulle',
  'tuned',
  'tunet',
  'tunga',
  'tunge',
  'tungt',
  'tuppe',
  'turar',
  'turen',
  'turer',
  'turte',
  'tusen',
  'tusle',
  'tutta',
  'tvang',
  'tveit',
  'tvers',
  'tvert',
  'tvile',
  'tweak',
  'tweet',
  'twice',
  'twimg',
  'twist',
  'tycks',
  'tyder',
  'tygge',
  'tykkt',
  'tynne',
  'tynnt',
  'typen',
  'typer',
  'types',
  'typet',
  'tyske',
  'tyson',
  'tzuer',
  'ucits',
  'udemy',
  'udisc',
  'uenig',
  'uffda',
  'ufint',
  'uhell',
  'ukene',
  'ukens',
  'ukers',
  'uklar',
  'ulike',
  'ulikt',
  'ultra',
  'ulven',
  'uncle',
  'under',
  'ungar',
  'ungen',
  'unger',
  'unike',
  'unikt',
  'union',
  'unite',
  'unito',
  'unity',
  'unner',
  'until',
  'upper',
  'uppet',
  'uredd',
  'urlen',
  'urler',
  'usage',
  'users',
  'using',
  'ustyr',
  'utdyp',
  'utfor',
  'utils',
  'utsyr',
  'uttak',
  'uuuuh',
  'uuuut',
  'vacay',
  'vafle',
  'vakke',
  'vakre',
  'valgt',
  'valid',
  'valpa',
  'valpe',
  'value',
  'valve',
  'vandt',
  'vanna',
  'vanne',
  'varen',
  'varer',
  'vargo',
  'varme',
  'varmt',
  'varre',
  'varte',
  'vaska',
  'vaske',
  'vasse',
  'vault',
  'vedde',
  'veden',
  'vedum',
  'veeet',
  'vegar',
  'vegas',
  'vegne',
  'veide',
  'veien',
  'veier',
  'veker',
  'vekke',
  'vekst',
  'vekta',
  'vekte',
  'veldi',
  'velge',
  'venne',
  'venta',
  'vente',
  'verca',
  'verda',
  'verdi',
  'verdt',
  'verge',
  'verne',
  'verra',
  'verre',
  'versa',
  'verse',
  'versj',
  'verst',
  'verve',
  'vesen',
  'veske',
  'vesla',
  'vetle',
  'vetta',
  'vettu',
  'vicky',
  'video',
  'views',
  'vifte',
  'viggo',
  'vikre',
  'vilde',
  'vimeo',
  'vindu',
  'vinen',
  'viner',
  'vinne',
  'vinni',
  'viper',
  'vippe',
  'vipps',
  'viral',
  'virdi',
  'virer',
  'vires',
  'virka',
  'virke',
  'virus',
  'viser',
  'vises',
  'visit',
  'visne',
  'visse',
  'visst',
  'viste',
  'vital',
  'vitec',
  'vitne',
  'vodka',
  'voffe',
  'vofse',
  'vogna',
  'voice',
  'voile',
  'vokal',
  'vokse',
  'vokst',
  'volda',
  'volum',
  'vonde',
  'vondt',
  'vorri',
  'vorse',
  'vossa',
  'voten',
  'votes',
  'votet',
  'vouch',
  'vowel',
  'vpner',
  'vraka',
  'vrake',
  'vunne',
  'vunni',
  'waaat',
  'wagen',
  'wales',
  'walls',
  'wanke',
  'wanna',
  'wants',
  'waste',
  'watch',
  'water',
  'weary',
  'weber',
  'wedel',
  'weeks',
  'wefew',
  'wefwe',
  'weing',
  'weird',
  'welsh',
  'wendy',
  'weren',
  'whaat',
  'whack',
  'whale',
  'whatt',
  'wheel',
  'where',
  'which',
  'while',
  'whine',
  'white',
  'whois',
  'whole',
  'wicky',
  'width',
  'wight',
  'wilde',
  'wilfa',
  'willy',
  'wilma',
  'windy',
  'wines',
  'wiper',
  'wipes',
  'wired',
  'woher',
  'woman',
  'women',
  'wooop',
  'woops',
  'words',
  'works',
  'world',
  'worms',
  'worst',
  'worth',
  'would',
  'wraps',
  'write',
  'wrong',
  'wrote',
  'wuath',
  'wuhan',
  'xcode',
  'yagni',
  'yahoo',
  'yappi',
  'ydmyk',
  'years',
  'yenna',
  'yesss',
  'yield',
  'yktes',
  'yngre',
  'yngst',
  'yorky',
  'yoshe',
  'young',
  'yrket',
  'ytret',
  'yummy',
  'yyyee',
  'yyyes',
  'zahra',
  'zanto',
  'zappa',
  'zdnet',
  'zelda',
  'zenta',
  'zgrep'
], 123456).filter(word => word.match(letters));

function shuffle(array: string[], seed: number) {
  let m = array.length, t, i;
  while (m) {
    i = Math.floor(random(seed) * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed
  }

  return array;
}

function random(seed: number) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}
